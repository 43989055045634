import React from 'react';
import classnames from 'classnames';
import SvgSymbols from 'assets/svg/svg-symbols.svg';
import './styles.scss';

const Icon = ({ name, className = '', size = '' }) => (
  <svg
    className={classnames(`cr-icon cr-icon-${name}`, className)}
    style={{ fontSize: size }}
  >
    <use xlinkHref={`${SvgSymbols}#${name}`} width="100%" height="100%" />
  </svg>
);

export default Icon;
